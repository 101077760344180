import React, { useState } from 'react';

const BasicContext = React.createContext({
  ipData: {},
  setIpData: () => {}
});

export const BasicContextProvider = props => {
  const [ipData, setIpData] = useState();

  return <BasicContext.Provider
    value={{
      ipData,
      setIpData,
    }}
  >
    {props.children}
  </BasicContext.Provider>

} 

export default BasicContext;