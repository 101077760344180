import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BasicContextProvider } from './context/basic-context';
import { Suspense } from 'react';
import LoadingSpinner from './components/ui/loading/LoadingSpinner';

const App = React.lazy(()=> import('./App'));

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey='6LfBazAcAAAAADyA8xzdsxGi-HZwyjbQVvzsw_91' >
      <BasicContextProvider>
        <ParallaxProvider>
          <BrowserRouter>
            <Suspense fallback={<LoadingSpinner />}>
              <App />
            </Suspense>
          </BrowserRouter>
        </ParallaxProvider>
      </BasicContextProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
